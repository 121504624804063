@mixin responsive-smaller($size)
{
	@media only screen and (max-width: $size - 1px) {@content;}
}

@mixin responsive-larger($size)
{
	@media only screen and (min-width: $size) {@content;}
}

@mixin responsive-inner($breakpoints, $media)
{
	
	@if map-has-key($breakpoints, $media)
	{
		
		$breakpoint-sizes: map-get($breakpoints, $media);

		
		$min-bp: nth($breakpoint-sizes, 1);
		$max-bp: nth($breakpoint-sizes, 2);

		
		@if $max-bp > 0
		{
			@if $min-bp == 0
			{
				@media only screen and (max-width: #{$max-bp - 1}px) {@content;}
			}
			@else
			{
				@media only screen and (min-width: #{$min-bp}px) and (max-width: #{$max-bp - 1}px) {@content;}
			}
		}
		@else
		{
			@media only screen and (min-width: #{$min-bp}px) {@content;}
		}
	}
}

@mixin responsive($medias...)
{
	
	$cutoffs: (400, 850, 1000);


	$breakpoints: (

		
		small: (0, nth($cutoffs, 1)),
		medium: (nth($cutoffs, 1), nth($cutoffs, 2)),
		large: (nth($cutoffs, 2), nth($cutoffs, 3)),
		xlarge: (nth($cutoffs, 3), -1),

		
		mobile: (0, nth($cutoffs, 2)), 
		desktop: (nth($cutoffs, 2), -1), 

		
		phone: (0, nth($cutoffs, 1)),
		tablet: (nth($cutoffs, 1), nth($cutoffs, 2)),
		desktop-sd: (nth($cutoffs, 2), nth($cutoffs, 3)),
		desktop-hd: (nth($cutoffs, 3), -1)

	);
	@each $media in $medias {
		@include responsive-inner($breakpoints, $media) {
			@content;
		}
	}
}
